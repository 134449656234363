import { navigate } from 'gatsby-link';
import React from 'react';
import googleAnalitycsEvents from '../../../utils/googleAnalitycsEvents';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import './styles.scss';

const ApplyNowItem = ({ title, actionButton }) => (
  <div className="apply-now-item">
    <span className="title" dangerouslySetInnerHTML={{ __html: title }} />
    <PrimaryButton
      size="xxs"
      text={actionButton.text}
      onClick={() => {
        googleAnalitycsEvents('click', { event_category: 'apply_now_team' });
        navigate(actionButton.action);
      }}
    />
  </div>
);

export default ApplyNowItem;
