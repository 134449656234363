import React from 'react';
import { Link } from 'gatsby';

import IdeaIcon from '../../assets/idea.svg';
import './styles.scss';

const PageFloatButton = ({ data }) => (
  <Link to="/contacts?subject=project">
    <div className="page-float-button">
      <div className="icon">
        <img className="icon-on" src={IdeaIcon} alt="Idea On Icon" />
        <img className="icon-off" src={IdeaIcon} alt="Idea Off Icon" />
      </div>
      <p>{data?.text}</p>
    </div>
  </Link>
);

export default PageFloatButton;
