import React, { forwardRef } from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import Section from '../Section/Section';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import TextTitle from '../TextTitle/TextTitle';
import TextNormalStripes from '../TextNormalStripes/TextNormalStripes';

const SectionWhatWeDo = forwardRef((props, ref) => (
  <Section id="about">
    <div ref={ref} />
    <SectionTitle title={props.data.title} />
    <ContentWrapper>
      <TextTitle>{props.data.headline}</TextTitle>
      <TextNormalStripes text={props.data.description} />
    </ContentWrapper>
  </Section>
));

export default SectionWhatWeDo;
