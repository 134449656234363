import React from 'react';
import { navigate } from 'gatsby';
import Section from '../Section/Section';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import './styles.scss';

const ContactBanner = ({ data }) => (
  <Section style={{ marginBottom: '48px' }}>
    <div className="contact-banner-bg">
      <div className="banner-bg-left" />
      <div className="banner-bg-right" />
    </div>
    <ContentWrapper style={{ padding: '0px' }}>
      <div className="contact-banner-content">
        <div className="card-content left">
          <h2
            className="card-title"
            dangerouslySetInnerHTML={{ __html: data.left_card.title }}
          />
          <div className="button-wrapper">
            <button
              type="button"
              className="card-button button-animation"
              onClick={() => navigate('/contacts?subject=idea')}
            >
              {data.left_card.btn_label}
            </button>
          </div>
        </div>
        <div className="card-content right">
          <h2
            className="card-title"
            dangerouslySetInnerHTML={{ __html: data.right_card.title }}
          />
          <div className="button-wrapper">
            <button
              type="button"
              className="card-button button-animation"
              onClick={() => navigate('/contacts?subject=scale')}
            >
              {data.right_card.btn_label}
            </button>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </Section>
);

export default ContactBanner;
