import React from 'react';
import Section from '../Section/Section';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import TextTitle from '../TextTitle/TextTitle';
import TextNormalStripes from '../TextNormalStripes/TextNormalStripes';
import './styles.scss';
import ValueItem from './ValueItem/ValueItem';

const SectionValues = ({ data }) => (
  <Section>
    <ContentWrapper>
      <TextTitle>{data.title}</TextTitle>
      <TextNormalStripes text={data.description} />
      <div className="section-values">
        {data.blocks.map((value, i) => (
          <ValueItem value={value} key={i.toString()} />
        ))}
      </div>
    </ContentWrapper>
  </Section>
);

export default SectionValues;
