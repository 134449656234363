import React from 'react';
import sanitizeHtml from 'sanitize-html';
import './styles.scss';

const TextTitle = (props) => (
  <h3
    className="text-title"
    dangerouslySetInnerHTML={{ __html: sanitizeHtml(props.children) }}
  />
);

export default TextTitle;
