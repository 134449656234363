import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { Parallax } from 'react-parallax';

import Layout from '../layouts/layout';

import SectionHero from '../components/SectionHero/SectionHero';
import SectionWhatWeDo from '../components/SectionWhatWeDo/SectionWhatWeDo';
import ContactBanner from '../components/ContactBanner/ContactBanner';
import SectionHowWeDoIt from '../components/SectionHowWeDoIt/SectionHowWeDoIt';
import SectionSkills from '../components/SectionSkills/SectionSkills';
import SectionTechnologies from '../components/SectionTechnologies/SectionTechnologies';
import SectionPartners from '../components/SectionPartners/SectionPartners';
import SectionOurPeople from '../components/SectionOurPeople/SectionOurPeople';
import SectionValues from '../components/SectionValues/SectionValues';
import SectionApplyNow from '../components/SectionApplyNow/SectionApplyNow';
import SEO from '../components/SEO';
import CookieBar from '../components/CookieBar/CookieBar';
import PageFloatButton from '../components/PageFloatButton/PageFloatButton';

const Page = ({ pageContext, data }) => {
  const {
    heroSection,
    whatWeDoSection,
    howWeDoItSection,
    contactBannerSection,
    skillSection,
    partnerSection,
    ourPeopleSection,
    valuesSection,
    techSection,
    applyNowSection,
    floatButton,
    seo,
  } = data?.content.frontmatter;
  const whatWeDoRef = useRef();

  const scrollHandler = () => {
    whatWeDoRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const url = typeof window !== 'undefined' ? window.location.href : '';

  const getBaseStyle = (percentage) => ({
    opacity: percentage * 1.5,
    transition: 'all 0.2s 0s linear',
  });

  return (
    <Layout
      showHeaderAfterScroll
      header={data.header}
      footer={data.footer}
      slug={pageContext.slug}
    >
      <SEO seo={seo} url={url} />
      <SectionHero
        header={data.header}
        data={heroSection}
        slug={pageContext.slug}
        onClickScroll={scrollHandler}
      />
      <PageFloatButton data={floatButton} />
      <Parallax
        renderLayer={(percentage) => (
          <div style={getBaseStyle(percentage)}>
            <SectionWhatWeDo ref={whatWeDoRef} data={whatWeDoSection} />
          </div>
        )}
      />
      <Parallax
        renderLayer={(percentage) => (
          <div style={getBaseStyle(percentage)}>
            <SectionHowWeDoIt data={howWeDoItSection} />
          </div>
        )}
      />
      <Parallax
        renderLayer={(percentage) => (
          <div style={getBaseStyle(percentage)}>
            <ContactBanner data={contactBannerSection} />
          </div>
        )}
      />
      <Parallax
        renderLayer={(percentage) => (
          <div style={getBaseStyle(percentage)}>
            <SectionSkills data={skillSection} />
          </div>
        )}
      />
      <Parallax
        renderLayer={(percentage) => (
          <div style={getBaseStyle(percentage)}>
            <SectionTechnologies data={techSection} />
          </div>
        )}
      />
      <Parallax
        renderLayer={(percentage) => (
          <div style={getBaseStyle(percentage)}>
            <SectionPartners data={partnerSection} />
          </div>
        )}
      />
      <Parallax
        renderLayer={(percentage) => (
          <div style={getBaseStyle(percentage)}>
            <SectionOurPeople data={ourPeopleSection} />
          </div>
        )}
      />
      <Parallax
        renderLayer={(percentage) => (
          <div style={getBaseStyle(percentage)}>
            <SectionValues data={valuesSection} />
          </div>
        )}
      />
      <Parallax
        renderLayer={(percentage) => (
          <div style={getBaseStyle(percentage)}>
            <SectionApplyNow data={applyNowSection} />
          </div>
        )}
      />

      <CookieBar data={data.cookies_bar} />
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query PageBySlug($id: String!, $language: String!) {
    content: markdownRemark(
      id: { eq: $id }
      frontmatter: { language: { eq: $language } }
    ) {
      frontmatter {
        seo {
          title
          description
          facebookAppID
          image
          keywords
          twitterUsername
        }
        title
        linkUrl
        language
        heroSection {
          title
        }
        floatButton {
          text
        }
        whatWeDoSection {
          title
          headline
          description
        }
        howWeDoItSection {
          title
          blocks {
            img
            alt
            title
            description
          }
        }
        skillSection {
          title
          blocks {
            img
            alt
            title
            description
          }
        }
        techSection {
          title
          blocks {
            img
            alt
            imgColor
          }
        }
        partnerSection {
          title
          blocks {
            img
            alt
            title
          }
        }
        ourPeopleSection {
          title
          description
          blocks {
            img
            alt
            description
            title
          }
          applyNow {
            title
            actionButton {
              text
              action
            }
          }
        }
        valuesSection {
          title
          description
          blocks {
            description
            title
          }
        }
        applyNowSection {
          description
          actionButton {
            text
            action
          }
          backgroundImage
        }
        contactBannerSection {
          left_card {
            title
            btn_label
          }
          right_card {
            title
            btn_label
          }
        }
      }
    }
    header: markdownRemark(
      frontmatter: {
        templateKey: { eq: "navbar" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        language
        logo {
          image
          imageAlt
        }
        logoFixed {
          image
          imageAlt
        }

        menuItems {
          label
          linkUrl
          openNewTab
        }
      }
    }

    footer: markdownRemark(
      frontmatter: {
        templateKey: { eq: "footer" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        projectLabel
        termsLabel
        privacyLabel
        language
        logo {
          image
          imageAlt
        }

        description

        followus {
          socialmedia {
            logo
            link
          }
          title
        }

        getInTouch {
          title
          body
        }

        useful_links {
          title
          menu {
            title
            link
            openNewTab
          }
        }

        copyright
      }
    }

    cookies_bar: markdownRemark(
      frontmatter: {
        templateKey: { eq: "cookies_bar" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        language
        title
        message
        acceptButton
        closeButton
      }
    }
  }
`;
