import React, { useState } from 'react';
import { Parallax } from 'react-parallax';
import Navbar from './Navbar/Navbar';
import Navigation from '../Navigation/Navigation';
import './styles.scss';

import ArrowDownIcon from '../../assets/arrow_down_white.svg';
import Backdrop from '../Backdrop/Backdrop';

const SectionHero = ({ onClickScroll, header, data, slug }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <>
      <div className="section-hero-container">
        <div className="section-hero">
          <Navbar>
            <Navigation
              onMobileOpen={() => setMobileOpen(true)}
              header={header}
              slug={slug}
            />
          </Navbar>
          <div className="jumbo-title">
            <Parallax
              renderLayer={(percentage) => {
                if (percentage >= 0 && percentage < 1) {
                  return (
                    <h1
                      dangerouslySetInnerHTML={{ __html: data.title }}
                      className="animate fadeInLeft delay800"
                    />
                  );
                }
                return (
                  <h1
                    dangerouslySetInnerHTML={{ __html: data.title }}
                    style={{ opacity: 0 }}
                  />
                );
              }}
            />
          </div>
          <div className="scroll-down-container">
            <button
              type="button"
              className="scroll-down delay800 animate marginTop infinite"
              onClick={onClickScroll}
            >
              <img src={ArrowDownIcon} alt="Arrow down" />
            </button>
          </div>
        </div>
      </div>
      {mobileOpen && (
        <>
          <Backdrop onClick={() => setMobileOpen(false)} />
          <div className="mobile-nav">
            <Navigation
              onMobileOpen={() => setMobileOpen(false)}
              isMobile
              header={header}
              slug={slug}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SectionHero;
