import React from 'react';

import './styles.scss';

const SkillCard = ({ item, children }) => (
  <div className="skills-card-wrapper">
    <img src={item.img} alt={item.title} />
    <div className="card-content">
      <div className="skill-info">
        <h3 className="title">{item.title}</h3>
        <p dangerouslySetInnerHTML={{ __html: item.description }} />
        {children}
      </div>
    </div>
  </div>
);

export default SkillCard;
