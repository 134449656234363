import React from 'react';
import { Parallax } from 'react-parallax';
import isMobile from '../../utils/isMobile';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import TextTitle from '../TextTitle/TextTitle';

import './styles.scss';

import Section from '../Section/Section';
import SectionBackground from '../../assets/section_bg.svg';

/*
TODO - implement gatsby plugin image
*/

const SectionHowWeDoIt = ({ data }) => {
  const getBaseStyle = (percentage, index) => {
    if (!isMobile()) {
      const limitPercentageAnimation = 0.8;
      let animationValue = (percentage - limitPercentageAnimation) * 110;
      if (index % 2 === 0) {
        animationValue *= -1;
      }

      if (percentage <= limitPercentageAnimation) {
        return {
          marginTop: `${animationValue}px`,
          transition: 'all 0.2s 0s linear',
        };
      }

      return {
        marginTop: `0px`,
        transition: 'all 0.2s 0s linear',
      };
    }
    return null;
  };
  return (
    <Section id="how-we-do-it">
      <img
        className="background-section"
        src={SectionBackground}
        alt="background"
      />
      <ContentWrapper>
        <TextTitle>{data.title}</TextTitle>
        <div className="section-howwedoit">
          {data.blocks.map((elem, index) => (
            <div key={index.toString()}>
              <Parallax
                renderLayer={(percentage) => (
                  <div className="card" style={getBaseStyle(percentage, index)}>
                    <img
                      className="card-image"
                      src={elem.img}
                      alt={elem.title}
                    />
                    <p className="card-title">{elem.title}</p>
                    <p
                      className="card-text"
                      dangerouslySetInnerHTML={{ __html: elem.description }}
                    />
                  </div>
                )}
              />
            </div>
          ))}
        </div>
      </ContentWrapper>
    </Section>
  );
};

export default SectionHowWeDoIt;
