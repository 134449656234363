import React from 'react';
import './styles.scss';

const TechItem = ({ item }) => (
  <div className="tech-item">
    <div>
      <img
        draggable={false}
        onDragStart={(e) => {
          e.preventDefault();
        }}
        className="tech-img-color"
        alt={item.alt}
        title={item.alt}
        src={item.imgColor}
      />
      <img
        draggable={false}
        onDragStart={(e) => {
          e.preventDefault();
        }}
        className="tech-img-bw"
        src={item.img}
        alt={item.alt}
        title={item.alt}
      />
    </div>
  </div>
);

export default TechItem;
