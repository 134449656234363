import React from 'react';
import './styles.scss';

const PeopleItem = ({ item }) => (
  <div className="people-item">
    {item.img && (
      <img
        draggable={false}
        onDragStart={(e) => {
          e.preventDefault();
        }}
        src={item.img}
        alt={item.title}
      />
    )}
    <div className="info">
      <span className="info-title">{item.title}</span>
      <span className="info-description">{item.description}</span>
    </div>
  </div>
);

export default PeopleItem;
