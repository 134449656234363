import React, { useMemo } from 'react';
import { Parallax } from 'react-parallax';
import Carousel from 'react-multi-carousel';
import SectionTitle from '../SectionTitle/SectionTitle';
import Section from '../Section/Section';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import TextNormalStripes from '../TextNormalStripes/TextNormalStripes';
import PeopleItem from './PeopleItem/PeopleItem';
import './styles.scss';
import 'react-multi-carousel/lib/styles.css';
import ApplyNowItem from './ApplyNowItem/ApplyNowItem';

const SectionOurPeople = ({ data }) => {
  const responsive = {
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 3,
    },
    xsTablet: {
      breakpoint: { max: 700, min: 480 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  const team = useMemo(() => data.blocks.concat(data.applyNow), [data]);

  return (
    <Section id="team">
      <SectionTitle title={data.title} />
      <ContentWrapper>
        <TextNormalStripes text={data.description} />
        <Parallax
          renderLayer={(percentage) => (
            <div
              className={`our-people-grid ${
                percentage >= 0.5 ? 'dominoAnimation' : ''
              }`}
              style={{
                opacity: percentage < 0.5 ? 0 : 1,
                transition: 'all 0.2s 0s linear',
              }}
            >
              {team.map((person, i) => {
                if (i === team.length - 1) {
                  return (
                    <ApplyNowItem
                      key={i.toString()}
                      title={person.title}
                      actionButton={person.actionButton}
                    />
                  );
                }
                return <PeopleItem key={i.toString()} item={person} />;
              })}
            </div>
          )}
        />

        <Carousel
          swipeable
          responsive={responsive}
          removeArrowOnDeviceType={['tablet', 'xsTablet']}
          transitionDuration={500}
          containerClass="people-carousel"
        >
          {team.map((person, i) => {
            if (i === team.length - 1) {
              return (
                <ApplyNowItem
                  key={i.toString()}
                  title={person.title}
                  actionButton={person.actionButton}
                />
              );
            }

            return <PeopleItem key={i.toString()} item={person} />;
          })}
        </Carousel>
      </ContentWrapper>
    </Section>
  );
};

export default SectionOurPeople;
