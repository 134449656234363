import React from 'react';
import './styles.scss';
import StripesBlue from '../../assets/stripes_blue.svg';

const SectionDarkTitle = (props) => {
  const { title } = props;

  return (
    <div className="section-dark-title">
      <img className="icon" alt="icon" src={StripesBlue} />
      <span className="title">{title}</span>
    </div>
  );
};

export default SectionDarkTitle;
