import React from 'react';
import Carousel from 'react-multi-carousel';
import Section from '../Section/Section';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import SectionDarkTitle from '../SectionDarkTitle/SectionDarkTitle';
import TechItem from './TechItem/TechItem';
import './styles.scss';
import 'react-multi-carousel/lib/styles.css';

const SectionTechnologies = ({ data }) => {
  const responsive = {
    lgDesktop: {
      breakpoint: { max: 5000, min: 1366 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 1366, min: 1024 },
      items: 6,
    },
    tabletLandscape: {
      breakpoint: { max: 1024, min: 768 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 480, min: 360 },
      items: 1,
    },
    xsMobile: {
      breakpoint: { max: 360, min: 0 },
      items: 1,
    },
  };

  const { title, blocks } = data;

  return (
    <Section>
      <ContentWrapper>
        <SectionDarkTitle title={title} />
        <div className="section-technologies">
          <Carousel
            swipeable
            responsive={responsive}
            removeArrowOnDeviceType={[
              'lgDesktop',
              'desktop',
              'tabletLandscape',
              'tablet',
            ]}
            transitionDuration={500}
          >
            {blocks.map((tech, i) => (
              <TechItem key={i.toString()} item={tech} />
            ))}
          </Carousel>
        </div>
      </ContentWrapper>
    </Section>
  );
};

export default SectionTechnologies;
