import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import SectionTitle from '../SectionTitle/SectionTitle';
import SkillCard from './SkillCard/SkillCard';
import ArrowLeftIcon from '../../assets/arrow_left.svg';

import './styles.scss';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

SwiperCore.use([Navigation, EffectFade]);

const SectionSkills = ({ data }) => {
  const { title, blocks } = data;

  return (
    <div className="section-skills" id="our-skills">
      <SectionTitle title={title} />
      <Swiper
        className="skills-swiper"
        loop
        navigation={{
          prevEl: '.swiper-navigation__prev',
          nextEl: '.swiper-navigation__next',
        }}
      >
        {blocks.map((item, index) => (
          <SwiperSlide className="swiper-item" key={index.toString()}>
            <SkillCard item={item} />
          </SwiperSlide>
        ))}
        <div className="navigation-wrapper">
          <div className="navigation-content">
            <div className="swiper-navigation swiper-navigation__prev">
              <img
                className="navigation-icon"
                src={ArrowLeftIcon}
                alt="Arrow Left icon"
              />
            </div>
            <div className="swiper-navigation swiper-navigation__next">
              <img
                className="navigation-icon"
                src={ArrowLeftIcon}
                alt="Arrow Left icon"
              />
            </div>
          </div>
        </div>
      </Swiper>
    </div>
  );
};

export default SectionSkills;
