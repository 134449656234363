import React from 'react';
import Carousel from 'react-multi-carousel';
import Section from '../Section/Section';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import SectionDarkTitle from '../SectionDarkTitle/SectionDarkTitle';
import './styles.scss';
import 'react-multi-carousel/lib/styles.css';

const SectionTechnologies = ({ data }) => {
  const responsive = {
    lgDesktop: {
      breakpoint: { max: 5000, min: 1366 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1366, min: 1024 },
      items: 3,
    },
    tabletLandscape: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 360 },
      items: 1,
    },
    xsMobile: {
      breakpoint: { max: 360, min: 0 },
      items: 1,
    },
  };

  const { title, blocks } = data;

  return (
    <Section>
      <ContentWrapper>
        <SectionDarkTitle title={title} />
        <div className="section-partners">
          <Carousel
            swipeable
            responsive={responsive}
            transitionDuration={500}
            removeArrowOnDeviceType={[
              'lgDesktop',
              'desktop',
              'tabletLandscape',
              'tablet',
            ]}
            itemClass="partner-list-item"
          >
            {blocks.map((partner) => (
              <img
                key={title}
                draggable={false}
                onDragStart={(e) => {
                  e.preventDefault();
                }}
                alt={partner.title}
                src={partner.img}
              />
            ))}
          </Carousel>
        </div>
      </ContentWrapper>
    </Section>
  );
};

export default SectionTechnologies;
