import React from 'react';
import sanitizeHtml from 'sanitize-html';
import './styles.scss';

const ValueItem = ({ value }) => (
  <div className="value-item">
    <div className="value-item-title">
      <span>{value.title}</span>
    </div>
    <div className="value-item-description">
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(value.description),
        }}
      />
    </div>
  </div>
);

export default ValueItem;
