import React from 'react';
import './styles.scss';
import { navigate } from 'gatsby';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import googleAnalitycsEvents from '../../utils/googleAnalitycsEvents';

const SectionApplyNow = ({ data }) => (
  <div className="section-apply-now" id="apply-now">
    <div className="apply-now-container">
      <span
        className="apply-now-text"
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
      <PrimaryButton
        text={data.actionButton.text}
        onClick={() => {
          googleAnalitycsEvents('click', {
            event_category: 'apply_now_section',
          });
          navigate(data.actionButton.action);
        }}
      />
    </div>
    <div className="apply-now-image">
      <img src={data.backgroundImage} alt="Join Us" />
    </div>
  </div>
);

export default SectionApplyNow;
